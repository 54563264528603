<template>
  <v-container fluid>
    <!-- 문자 발송 다이얼로그 -->
    <v-dialog v-model="smsDialog" max-width="600px">
      <v-card>
        <v-card-title>자유 형식 문자 발송</v-card-title>
        <v-card-text>
          <div class="mb-4">
            <div class="subtitle-1 mb-2">
              발송 대상 고객 ({{ selectedCustomers.length }}명):
            </div>
            <div
              style="
                max-height: 100px;
                overflow-y: auto;
                padding: 8px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
              "
            >
              {{
                selectedCustomers
                  .map((customer) => customer.user.name)
                  .join(', ')
              }}
            </div>
          </div>
          <v-textarea
            v-model="smsMessage"
            label="발송할 메시지"
            placeholder="발송할 메시지를 입력하세요..."
            rows="6"
            outlined
            :rules="[(v) => !!v || '메시지를 입력해주세요!']"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="smsDialog = false">취소</v-btn>
          <v-btn
            color="primary"
            :disabled="!smsMessage"
            @click="showConfirmSmsDialog"
          >
            다음
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 문자 발송 확인 다이얼로그 -->
    <v-dialog v-model="confirmSmsDialog" max-width="600px">
      <v-card>
        <v-card-title>문자 내용 확인</v-card-title>
        <v-card-text>
          <div
            class="text-body-2 mb-4"
            style="
              white-space: pre-wrap;
              word-break: break-word;
              background-color: #f0f0f0;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-family: monospace;
              text-align: left;
            "
          >
            {{ smsMessage }}
          </div>
          <div class="font-weight-bold">
            선택된 {{ selectedCustomers.length }}명의 고객에게 위 내용으로
            문자를 발송하시겠습니까?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmSmsDialog = false"
            >취소</v-btn
          >
          <v-btn color="primary" :loading="smsLoading" @click="sendSms">
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 선정산 장애 공지 다이얼로그 -->
    <v-dialog v-model="disabilityNoticeDialog" max-width="600px">
      <v-card>
        <v-card-title>선정산 장애 안내문자 발송</v-card-title>
        <v-card-text>
          <p>아래 {{ selectedCustomers.length }}명의 고객들에게 선정산 장애 안내문자를 발송하시겠습니까?</p>
          <div
            style="
              max-height: 100px;
              overflow-y: auto;
              margin-bottom: 10px;
              padding: 8px;
              border: 1px solid #e0e0e0;
              border-radius: 4px;
            "
          >
            {{ selectedCustomers.map(customer => customer.user.name).join(', ') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="disabilityNoticeDialog = false">취소</v-btn>
          <v-btn color="primary" @click="showConfirmDisabilityNotice">다음</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 선정산 장애 공지 확인 다이얼로그 -->
    <v-dialog v-model="confirmDisabilityNoticeDialog" max-width="600px">
      <v-card>
        <v-card-title>문자 내용 확인</v-card-title>
        <v-card-text>
          <div
            class="text-body-2 mb-4"
            style="
              white-space: pre-wrap;
              word-break: break-word;
              background-color: #f0f0f0;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-family: monospace;
              text-align: left;
            "
          >
            {{ disabilityNoticeMessage }}
          </div>
          <div class="font-weight-bold">
            선택된 {{ selectedCustomers.length }}명의 고객에게 위 내용으로 문자를 발송하시겠습니까?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDisabilityNoticeDialog = false">취소</v-btn>
          <v-btn color="primary" :loading="smsLoading" @click="sendDisabilityNotice">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <h2>{{ pageTitle }}</h2>
        <!-- 검색 필터 -->
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="2">
                <v-select
                  v-model="filters.contractStatus"
                  :items="contractStatusOptions"
                  label="계약 상태"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  v-model="filters.isEarlyPaymentEnabled"
                  :items="earlyPaymentOptions"
                  label="선정산 여부"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="filters.keyword"
                  label="검색어"
                  append-icon="mdi-magnify"
                  clearable
                  @keyup.enter="search"
                />
              </v-col>
              <v-col cols="12" sm="3" class="d-flex align-center">
                <v-btn
                  color="primary"
                  prepend-icon="mdi-microsoft-excel"
                  @click="downloadExcel"
                >
                  엑셀 다운로드
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- 액션 버튼 -->
        <v-row class="mb-4">
          <v-col>
            <v-btn color="primary" class="mr-2" @click="openPublishModal">
              선정산 장애 공지
            </v-btn>
            <v-btn color="primary" class="mr-2" @click="openDocumentModal">
              자유 형식 문자 발송
            </v-btn>
            <!-- <v-btn color="primary" @click="openAddCustomerModal">
              가맹점 추가
            </v-btn> -->
          </v-col>
        </v-row>

        <!-- 데이터 테이블 -->
        <v-data-table
          v-model="selectedCustomers"
          :headers="headers"
          :items="customers"
          :loading="loading"
          :server-items-length="totalCustomers"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [15, 30, 50, 100],
          }"
          show-select
          item-key="user.id"
          @input="handleSelectionChange"
        >
          <!-- ID 열 -->
          <template v-slot:item.id="{ item }">
            <router-link
              :to="{
                name: 'myfinbiz/customer/detail',
                params: { id: item.id },
              }"
            >
              {{ item.id }}
            </router-link>
          </template>

          <!-- 가입일 열 -->
          <template v-slot:item.modified="{ item }">
            {{ formatDate(item.modified) }}
          </template>

          <!-- 상호 열 -->
          <template v-slot:item.user.store.name="{ item }">
            {{ item.user.store.name }}
          </template>

          <!-- 주소 열 -->
          <template v-slot:item.user.store.address1="{ item }">
            {{ item.user.store.address1 }}
          </template>

          <!-- 이름 -->
          <template v-slot:item.user.name="{ item }">
            <span @click="goToUserDetail(item.user.id)" class="clickable-cell">
              {{ item.user.name }}
            </span>
          </template>

          <!-- 전화번호 열 -->
          <template v-slot:item.user.phone="{ item }">
            {{ formatPhoneNumber(item.user.phone) }}
          </template>

          <!-- 계약상태 열 -->
          <template v-slot:item.user.contract.status="{ item }">
            <v-chip
              :color="getContractStatusColor(item.user.contract.status)"
              small
            >
              {{ getContractStatusText(item.user.contract.status) }}
            </v-chip>
          </template>

          <!-- 선정산가능 열 -->
          <template v-slot:item.user.contract.isEarlyPaymentEnabled="{ item }">
            <v-chip
              v-if="item.user.contract.isEarlyPaymentEnabled"
              color="success"
              small
            >
              사용중
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MyfinbizCustomers',

  data() {
    return {
      loading: false,
      customers: [],
      totalCustomers: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['created'],
        sortDesc: [true],
      },
      // 필터 옵션
      filters: {
        keyword: '',
        contractStatus: null,
        isLoanable: null,
        isEarlyPaymentEnabled: null,
      },
      // 테이블 헤더
      headers: [
        { text: 'ID', value: 'id', width: '80' },
        { text: '가입일', value: 'modified' },
        { text: '상호', value: 'user.store.name' },
        { text: '주소', value: 'user.store.address1' },
        { text: '이름', value: 'user.name' },
        { text: '전화번호', value: 'user.phone' },
        { text: '계약상태', value: 'user.contract.status' },
        { text: '선정산여부', value: 'user.contract.isEarlyPaymentEnabled' },
        { text: '메모', value: 'latestMemoContent' },
      ],
      // 필터 옵션 목록
      contractStatusOptions: [],
      loanableOptions: [
        { text: '가능', value: true },
        { text: '불가능', value: false },
      ],
      earlyPaymentOptions: [
        { text: '사용중', value: true },
        { text: '미사용', value: false },
      ],
      // 문자 발송 관련
      selectedCustomers: [],
      smsDialog: false,
      smsMessage: '',
      smsLoading: false,
      confirmSmsDialog: false,
      disabilityNoticeDialog: false,
      confirmDisabilityNoticeDialog: false,
      disabilityNoticeMessage: `현재 여신금융협회에서 발생한 장애로 인해 선정산 집행이 지연되고 있습니다.
정확한 선정산을 위해서는 여신금융협회의 정보가 필요해서 신속한 복구를 기다리고 있습니다.

장애 복구가 되는 대로 빠르게 선정산 집행을 진행하겠습니다.
(장애 발생시 평균적 복구 시간 : 1~2시간)

문의 사항이 있으시면 채널톡에 남겨주시기 바랍니다.
채널톡 링크 : https://xkej0.channel.io/`,
    }
  },

  computed: {
    ...mapGetters('myfinbiz/auth', [
      'getToken',
      'isAuthenticated',
      'getContractStatusCodes',
      'getCodes',
      'getOrganization',
    ]),
    // 검색 파라미터
    searchParams() {
      const { page, itemsPerPage } = this.options
      return {
        page,
        limit: itemsPerPage,
        organizationId: this.getOrganization?.id,
        ...(this.filters.keyword && { keyword: this.filters.keyword }),
        ...(this.filters.contractStatus && {
          contractStatus: this.filters.contractStatus,
        }),
        ...(this.filters.isLoanable !== null && {
          isLoanable: this.filters.isLoanable,
        }),
        ...(this.filters.isEarlyPaymentEnabled !== null && {
          isEarlyPaymentEnabled: this.filters.isEarlyPaymentEnabled,
        }),
      }
    },
    // 페이지 제목
    pageTitle() {
      return `${this.getOrganization?.name || ''} 가맹점 목록`
    },
  },

  watch: {
    // 옵션이 변경되면 데이터 다시 로드
    options: {
      handler() {
        this.loadCustomers()
      },
      deep: true,
    },
    // 필터가 변경되면 데이터 다시 로드
    'filters.contractStatus'() {
      this.search()
    },
    'filters.isEarlyPaymentEnabled'() {
      this.search()
    },
  },

  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'myfinbiz/login' })
      return
    }
    this.contractStatusOptions = this.getContractStatusCodes
    this.loadCustomers()
  },

  methods: {
    // 가맹점 목록 조회
    async loadCustomers() {
      if (!this.isAuthenticated) return

      this.loading = true
      try {
        const response = await this.$myfinbizApi.customer.getList(
          this.searchParams
        )
        console.log('API Response:', response.data)

        // 응답에서 데이터 추출
        const { data: responseData } = response.data.data
        console.log(responseData)
        if (responseData && Array.isArray(responseData.data)) {
          this.customers = responseData.data
          this.totalCustomers = responseData.total
          this.selectedCustomers = [] // 데이터가 새로 로드될 때마다 선택 초기화
        } else {
          this.customers = []
          this.totalCustomers = 0
          console.warn('Unexpected data structure:', responseData)
        }
        console.log('Processed customers:', this.customers)
      } catch (error) {
        console.error('Failed to load customers:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '가맹점 목록을 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },

    // 검색
    search() {
      this.options.page = 1
      this.selectedCustomers = [] // 선택된 고객 목록 초기화
      this.loadCustomers()
    },

    // 계약 상태 색상
    getContractStatusColor(status) {
      // 기본 색상 매핑
      const colorMap = {
        started: 'success', // 계약중 - 초록색
        pending: 'warning', // 대기중 - 주황색
        terminated: 'error', // 해지 - 빨간색
        suspended: 'grey darken-1', // 일시중지 - 진한 회색
      }
      return colorMap[status] || 'grey lighten-1' // 매칭되는 상태가 없으면 연한 회색
    },

    // 계약 상태 텍스트
    getContractStatusText(status) {
      const statusCode = this.getCodes?.fundanow?.ContractsContractStatus?.find(
        (code) => code.code === status
      )
      return statusCode?.label || status
    },

    // 선정산 장애 공지 모달 열기
    openPublishModal() {
      if (!this.selectedCustomers?.length) {
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '문자를 발송할 고객을 선택해주세요.',
          color: 'warning',
        })
        return
      }

      this.disabilityNoticeDialog = true
    },

    // 선택된 고객 변경 처리
    handleSelectionChange(selected) {
      this.selectedCustomers = selected
      console.log('Selected customers:', this.selectedCustomers)
    },

    // 문자 발송 다이얼로그 표시
    openDocumentModal() {
      if (!this.selectedCustomers?.length) {
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '문자를 발송할 고객을 선택해주세요.',
          color: 'warning',
        })
        return
      }

      // 선택된 고객들의 정보 로깅
      console.log(
        'Opening SMS dialog with selected customers:',
        this.selectedCustomers.map((customer) => ({
          id: customer.user.id,
          name: customer.user.name,
        }))
      )

      this.smsMessage = ''
      this.smsDialog = true
    },

    // 문자 발송 확인 다이얼로그 표시
    showConfirmSmsDialog() {
      this.smsDialog = false
      this.confirmSmsDialog = true
    },

    // 문자 발송
    async sendSms() {
      if (this.smsLoading) return

      try {
        this.smsLoading = true
        await this.$myfinbizApi.customer.sendBulkSms({
          sender: '',
          userIds: this.selectedCustomers.map((customer) => customer.user.id),
          title: '문자 제목',
          message: this.smsMessage,
        })

        this.$store.dispatch('snackbar/showSnackbar', {
          text: '문자 발송에 성공하였습니다.',
          color: 'success',
        })
        this.confirmSmsDialog = false
      } catch (error) {
        console.error('Failed to send SMS:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '문자 발송에 실패하였습니다.',
          color: 'error',
        })
      } finally {
        this.smsLoading = false
      }
    },

    // 가맹점 추가 모달 열기
    openAddCustomerModal() {
      // TODO: 구현 예정
    },

    // 엑셀 다운로드
    downloadExcel() {
      // TODO: 구현 예정
    },

    // 날짜 포맷팅
    formatDate(date) {
      if (!date) return ''
      const d = new Date(date)
      const year = d.getFullYear()
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const day = String(d.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },

    // 전화번호 포맷팅
    formatPhoneNumber(phone) {
      if (!phone) return ''
      return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
    },

    goToUserDetail(userId) {
      window.open(`/fundanow/users/${userId}/detail`, '_blank')
    },
  },
}
</script>

<style scoped>
.v-data-table ::v-deep a {
  color: #1976d2;
  text-decoration: none;
}
.v-data-table ::v-deep a:hover {
  text-decoration: underline;
}

.clickable-cell {
  cursor: pointer;
  text-decoration: underline;
  color: #1976d2;
}

.clickable-cell:hover {
  color: #2196f3;
  font-weight: 500;
}
</style>
